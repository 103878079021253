/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'
// eslint-disable-next-line no-restricted-imports
import { Flex as ThemeUIFlex, FlexProps as ThemeUIFlexProps } from 'theme-ui'
import { TensorUIBaseProps } from 'typ'

export type FlexProps = Omit<ThemeUIFlexProps, 'sx' | 'style'> &
  TensorUIBaseProps
export const Flex = forwardRef<HTMLDivElement, FlexProps>(
  ({ style, ...props }, ref) => <ThemeUIFlex {...props} sx={style} ref={ref} />
)

Flex.displayName = 'Flex'
