/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'
// eslint-disable-next-line no-restricted-imports
import {
  Link as ThemeUIAnchor,
  LinkProps as ThemeUIAnchorProps,
} from 'theme-ui'
import { TensorUIBaseProps } from 'typ'

export type AnchorProps = Omit<ThemeUIAnchorProps, 'sx' | 'style'> &
  TensorUIBaseProps

export const Anchor = forwardRef<HTMLAnchorElement, AnchorProps>(
  ({ style, ...props }, ref) => (
    <ThemeUIAnchor {...props} sx={style} ref={ref} />
  )
)

Anchor.displayName = 'Anchor'
