/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'
// eslint-disable-next-line no-restricted-imports
import { Grid as ThemeUIGrid, GridProps as ThemeUIGridProps } from 'theme-ui'
import { TensorUIBaseProps } from 'typ'

export type GridProps = Omit<ThemeUIGridProps, 'sx' | 'style'> &
  TensorUIBaseProps

export const Grid = forwardRef<HTMLDivElement, GridProps>(
  ({ style, ...props }, ref) => <ThemeUIGrid {...props} sx={style} ref={ref} />
)

Grid.displayName = 'Grid'
